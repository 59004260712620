.hidden {
  display: none !important;
}

.form-error {
  .form-control {
    color:#FF4747;
    &::placeholder {
      color:#FF4747;
    }
  }

  p {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0;
    color: #FF4747;
    margin-top: 7px;
  }
}

.not-visible {
  height: 0 !important;
  visibility: hidden !important;
  overflow: hidden !important;
  opacity: 0 !important;
  transition: opacity .3s ease;
}