.footer{
	background-color: #000;
	.footer__top{
		background-color: rgba(255,255,255,.1);
		height: 40px;
		[class*="container"]{
			height: 100%;
		}
	}
	.footer__bottom{
		padding: 30px;
		img{
			display: block;
			margin: auto auto 12px auto;
		}
		small{color: #C69D51;}
	}
}


@media screen and (max-width: 768px){
	.footer{
		.footer__top{
			display: none;
		}
		.footer__bottom{
			img{max-width: 90px;}
		}
	}
}

// IPAD LANDSCAPE ONLY
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape){
	.footer{
		.footer__top{
			display: none;
		}
		.footer__bottom{
			img{max-width: 90px;}
		}
	}
}

// IPHONE X & WINDOWS PHONE LANDSCAPE ONLY
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (orientation: landscape) { 
	.iphone,
	.windowsphone{
		.footer{
			.footer__top{
				display: none;
			}
			.footer__bottom{
				img{max-width: 90px;}
			}
		}
	}
}