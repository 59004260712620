.page--gallery{
	padding-top: 70px;
	.gallery__wrapper{
		margin-top: 32px;
		margin-bottom: 35px;
		.gallery__content__wrapper{
			min-height: 50vh;
		}
		.gallery__tab{
			border-top:1px solid rgba(76,76,76, .25);
			border-bottom:1px solid rgba(76,76,76, .25);
			margin-bottom: 20px;
			.tabs-material{
				.tabs-material__list{
					border-bottom: 0;
					padding-bottom: 10px;
					color: #CDA966;
					&.active{
						a{
							color: #CDA966;
						}
					}
					a{
						color: #CDA966;
						opacity:1;
					}
				}
			}
			.tabs-material-line{height: 2px;background-color: #CDA966;bottom:1px;}
		}
		.tabs-material-content{
			.tabs-material-item{
				display: block;
				height: 0;
				overflow: hidden;
				visibility: hidden;
				opacity: 0;
				transition: opacity .3s ease;
				&.active{
					height: auto;
					overflow: auto;
					visibility: visible;
					opacity: 1;
				}
			}
		}
		.gallery__tab__content{
			@include clearfix;
			margin-left: -9px;
			margin-right: -9px;
			.gallery__thumb{
				position: relative;
				padding-bottom: 25%;
				height:0;
				min-height: 1px;
				float: left;
				&:hover{
					.gallery__thumb__inner{
						.overlay{
							visibility: visible;
							opacity: 1;
							z-index: 1;

							i{animation: slideInTop 0.4s ease}
							h5{animation: slideInBottom 0.4s ease}
						}
					}	
				}
				&.box__1{width: susy-span(4 of 12);}
				&.box__2{width: susy-span(6 of 12);}
				&.box__3{width: susy-span(8 of 12);}

				.gallery__thumb__inner{
					@include absolute(9px,9px,9px,9px)
					margin:auto;
					overflow: hidden;
					img{
						position: absolute;
						width: 100%;height: 100%;
						object-fit: cover;
					}
					.overlay{
						@include absolute(0,0,0,0);
						margin:auto;
						background-color: rgba(0,0,0,0.70);
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						color: #C69D51;
						transition: .3s ease;

						visibility: hidden;
						opacity: 0;
						z-index: 0;
					}
				}
			}
		}
	}
}

.page-load-status {
  // display: none; /* hidden by default */
  padding-top: 20px;
  text-align: center;
  color: #d4b16f;
}	

@media screen and (max-width: 768px){
	.page--gallery{
		padding-top: 37px;
		[class*="container"]{
			padding: 0 15px;
		}
		h1{font-size: 24px;margin-bottom: 30px;}
		.gallery__wrapper{
			.gallery__content__wrapper{min-height: 40vh;}
			.gallery__tab{
				padding: 10px 0;
				margin-bottom: 0;
				.chosen-container{
					width: 200px !important;
					text-align: left;
					padding: 0;
					.chosen-single{
						border-bottom: 0;
					}
					.chosen-drop{
						border: 1px solid #cecece;
						border-top: 1px solid #cecece;
					}
				}
				.form{
					select{
						border-bottom: 0px;
						width: 150px;
					}
				}
			}
			.gallery__tab__content{
				padding: 9px 0;
				min-height: 300px;
				.gallery__thumb{
					padding-bottom: 40%;
					&:hover{
						.gallery__thumb__inner{
							.overlay{
								visibility: hidden;
								opacity: 0;
								z-index: 0;
							}
						}	
					}
				}
			}
		}
	}
}