::-webkit-input-placeholder { color:rgba(212, 177, 111, .85) }
::-moz-placeholder { color:rgba(212, 177, 111, .85) } /* firefox 19+ */
:-ms-input-placeholder { color:rgba(212, 177, 111, .85) } /* ie */
input:-moz-placeholder { color:rgba(212, 177, 111, .85) }
.placeholder { color:rgba(212, 177, 111, .85) }

.form{
	width: 100%;
    margin: 0;
    padding: 0;
    input, textarea, select, button, a{
        &:focus,&:active{ outline: 0px solid transparent; }
    }
    input, textarea, select {
        position: relative;
        font-family: 'Forum', cursive;
        font-size:18px;
        color: inherit;         
        border: 0 solid transparent;
        border-bottom: 1px solid #979797;
        border-radius:0;
        padding: 5px 2px;
        -webkit-appearance: none;
        vertical-align: middle;
        width:auto;
        height:31px;
        background-color: transparent;
    }
    textarea{ 
        height:auto;
        resize: none;
        &.small{ height:auto; padding:10px; }
    }
    select{
    	background-image: url(/images/icon/ico-select-arrowdown.svg);
    	background-repeat: no-repeat;
    	background-position: right center;
    	background-size: 14px 9px;
        color:rgba(212, 177, 111, .85);
    	&:invalid {
    		color:rgba(212, 177, 111, .85);
    	}
    }
    input[type="checkbox"], 
    input[type="radio"]{
        &~ label{ display:inline-block; } 
    }
    input[type="file"]{
        background:white;
        border:0;
        padding:2px 0;
    }
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance:textfield;
    }  
    .field{
    	position: relative;
        max-width:100%;
        margin-bottom:20px;
        input, 
        select, 
        textarea{
           width:100%;
        }
        .chosen-container{
        	width: 100% !important;
        }
    }

    .chosen-container{
    	font-size: 18px;
    	font-family: 'Forum', cursive;
    	color: inherit;
    	.chosen-single{
    		height: 31px;
    		line-height: 31px;
    		font-family: inherit;
    		color: #D4B16F;

    		background-image: none;
    		box-shadow: none;
    		border: 0 solid transparent;
    		border-bottom: 1px solid #979797;
    		border-radius: 0;
			
    		div{
    			b{
    				background-image: url(/images/icon/ico-select-arrowdown.svg);
    				background-repeat: no-repeat;
    				background-size: 14px 9px;
    				background-position: center;
    			}
    		}
    	}
		
    	.chosen-drop{
    		margin-top: 0;
    		border: 1px solid #979797;
    		border-top: 0;
    		border-radius: 0;
    	}

    	.chosen-results{
    		margin: 0;
    		padding: 0;
    		color: inherit;
    		li{
    			padding: 10px 6px;
    			&.highlighted{
    				background-color: #D4B16F;
    				background-image: none;
    			}
    		}
    	}
    }
    .chosen-container-active{
    	&.chosen-with-drop{
    		.chosen-single{
    			background-image: none;
    			box-shadow: none;
    			border: 0 solid transparent;
    			border-bottom: 1px solid #979797;

    			div{
    				b{
    					transform: rotate(180deg);
    					background-position: center;
    				}
    			}
    		}
    	}
    }
}

.form-control {
  width: 100%;
  border: none;
  border-bottom: 1px solid #979797;
  outline: none;
  padding-bottom: 12px;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 19px;
  font-family: 'Forum', sans-serif;
  color: #737373;
  /* color: #979797; */
  &::placeholder {
    color: #D4B16F; 
    color: rgba(#D4B16F, .85);
  }
}

textarea {
  &.form-control {
    resize: none;
  }
}


.form-group {
  margin-bottom: 63px;
}

@media(max-width: 768px) {
  .form-control, textarea.form-control {
    padding-bottom: 4px;
    font-size: 16px;
    line-height: 18px;
  }

  select.form-control {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #ffffff;
    background: url(/images/contact/arrow-select.png) no-repeat 100% 33%;
    &.select-default {
      color: #D4B16F; 
      color: rgba(#D4B16F, .85);
      option {
        color: #737373;
      }
    }
  }

  .form-group {
    margin-bottom: 30px;
  }
}