.page--location{
	font-size: 18px;
	.content{padding: 20px 0;}
	p{
		font-size: inherit;
		letter-spacing: 0;
		line-height: 25px;
	}
	.location__desc{ 
		padding: 20px 0;
		border-bottom: 1px solid rgba(76,76,76,.25);
		h1{margin-bottom: 30px;}
		.location__desc__p{
			margin-bottom: 50px;
			p{
				span{
					font: inherit !important; 
					color: inherit !important;
				}
			}
		}
	}
	.location__advantage{
		padding: 70px 0;
		h1{
			font-size: 36px;
			margin-bottom: 30px;
		}
		.location__advantage__text{
			padding: 0 30px;
		}
		.location__advantage__p{
			p{
				span{
					font: inherit !important; 
					color: inherit !important;
				}
			}
			ul{
				font-size: inherit;
				letter-spacing: 0;
				line-height: 25px;
				li{
					position: relative;
					padding-left: 12px;
					&:before{
						content: '-';
						position: absolute;
						left:0;
						top:0;bottom:0;
						margin: auto;
					}
				}	
			}
		}
	}
	.location__map{
		margin-bottom: 80px;
		[class*="container"]{
			background-color: #f6f6f6;
			padding: 20px;
			.title{
				color: #4B4B4B;
				letter-spacing: 0;
				margin-bottom: 30px;
			}
			.subtitle{
				color: #000000;
				letter-spacing: 1.88px;
				line-height: 21px;
				margin-bottom: 30px;
			}
			.address{
				color: #4B4B4B;
				letter-spacing: 0;
				margin-bottom: 10px;
			}
			.phone{
				color: #4B4B4B;
				letter-spacing: 0;
			}
		}
		.map{
			@include size(100%, 600px);
			margin-top: 50px;
			background-color: #D8D8D8;
			border: 1px solid #D8D8D8;
		}
	}
}

@media screen and (max-width: 768px){
	.page--location{
		h1{font-size: 30px !important;}
		h4{font-size: 20px;}
		.content{padding: 10px 15px;}
		.img-cover_h410 {
			height: 300px;
		}
		.location__desc{
			.location__desc__p{margin-bottom: 10px;}
		}
		.location__advantage{
			padding: 30px 0;
			.row{
				display: flex;
				flex-direction: column;
				.col{
					float: none;
					&.m6{
						width: susy-span(12 of 12);
					}
				}
			}
			.location__advantage__img{
				margin-bottom: 30px;
				img{margin:auto;}
			}
			.location__advantage__text{
				padding:0;
				.location__advantage__p{
					text-align: justify;
				}
			}
		}
		.location__map{
			margin-left: -15px;
			margin-right: -15px;
			margin-bottom: 30px;
			[class*="container"]{
				padding: 15px 15px 30px 15px;
				.title{margin-bottom: 27px;}
			}
			.map{margin-top: 30px;}
		}
	}
}

@media screen and (max-width: 520px){
	.page--location{
		h1{font-size: 24px !important;}
		h4{font-size: 18px;}
		font-size: 16px;
		p{line-height: 23px;}
		.img-cover_h410{
			height: 205px;
		}
	}
}