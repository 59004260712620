.lang__bar{
    height: 100%;
    .lang__bar__li{
        padding:0 8px;
        border-right: 1px solid #E2E2E2;
        &:last-child{border-right: 0;}
        &.active,
        &:hover{
            .lang__bar__a{
                color: rgba(129, 129, 129, .8);
            }    
        }
        .lang__bar__a{
            color: rgba(61, 61, 61, .3);
        }
    }
}

.map{
   @include size(100%, 100%);
}

@media screen and (min-width: 521px)
and (max-width: 768px)
and (orientation: portrait){
    .primary-banner{
        &.img-cover_h205{
            height: 300px;
        }
    }
}