.tabs__ul{
	margin: 0;
	display: inline-block;
	position: relative;
	.tabs__li{
		display: inline-block;
		padding: 12px 25px;
		a{
			color: #CDA966;
			font-size: 16px;
			&:hover{
				color: darken(#CDA966, 5%);
			}
		}
	}
}
.tabs__line{
	height: 2px;
	width: 0;
	bottom: 1px;
	background-color: #C69D51;
	position: absolute;
}
.tab-content{
	display: none;
	opacity: 0;
	transition: opacity 0.3s linear;
	&.active{
		display: block;
	}
	&.fadeIn{
		opacity: 1
	}
}