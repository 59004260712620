.page-error {
  min-height: 100vh;
  width: 100%;
  background-image: url('/images/global/error-bg.jpg');
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .btn-back-to-home {
    background-color: #D4B16F;
    border-color: transparent;
    &:hover {
      background-color: darken(#D4B16F, 10%);
    }
    > img {
      vertical-align: -3px;
      margin-right: 7px;
    }
  }
}

.page-error__desc {
  color: #FFFFFF;
  margin-bottom: 130px;
}

.page-error__title {
  font-size: 72px;
  letter-spacing: 5px;
  line-height: 80px;
  letter-spacing: 0;
  color: #FFFFFF;
  margin-top: 118px;
}

@media(max-width: 768px) {
  .page-error__desc {
    font-size: 24px;
    line-height: 34px;
    padding: 0 15px;
    margin-bottom: 40px;
  }

  .page-error__title {
    font-size: 42px;
    line-height: 52px;
    margin-bottom: 20px;
  }
}