.unit-type-detail {
  background-color: #FDFBF8;

  .btn-download-brochure {
    margin-top: 8.5px;
    width: 210px;
    height: 47px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #C69D51;
    color: #C69D51;
    background-color: transparent;
    &:hover {
      background-color: #C69D51;
      color: #fff;
    }
  }

  .previous-page {
    margin-top: 20.5px;
    color: $primary-color;
    > img {
      margin-right: 10px;
      display: inline;
    }
  }

  .section-floor-plan {
    position: relative;
    height: 480px;
    width: 100%;
    background-color: #f5f5f5;
  }

  .section-floor-plan__img {
    z-index: 10;
    position: relative;
  }

  .section-floor-plan__loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 232px;
    z-index: 9;
    text-align: center;
    > img {
      display: inline;
    }
  }

  .toolbar {
    padding: 37px 0 56px 0;
    &:after {
      display: table;
      content: " ";
      clear: both;
    }
  }

  .toolbar__h {
    color: $base-black;
    display: inline;
  }

  .unit-detail-list {
    margin: 0 0 50px;
    padding: 0;
    li {
      padding-left: 10px;
      position: relative;
      &:before {
        content: "-";
        position: absolute;
        left: 0;
      }
      span{
        font: inherit !important; 
        color: inherit !important;
      }
    }

    > p {
      font-size: inherit;
      line-height: inherit;
      &:before {
        content: "-";
        margin-right: 3px;
      }
      &:last-child {
        margin-bottom: 50px;
      }
    }
  }
  
  .unit-facilities{
    display: flex;
    .col{
      float: left;
      min-height: 1px;
      margin-right: gutter(6);
      &:last-child{
        margin-right: 0;
      }
      &.m6{width: susy-span(6 narrow of 12 narrow);}
    }
  }

  .unit-plan {
    margin-bottom: 102px;
  }

  .tabs-material-item{
    .row{
      .col{
        &:first-child{
          padding-right: 8px;
        }
        &:last-child{
          padding-right: 8px;
        }
      }
    }
  }
}

@media(max-width: 991px) {
  .unit-type-detail {
    
    .btn-download-brochure {
      width: 160px;
      height: 38px; 
      font-size: 13px;
    }

    .previous-page {
      font-size: 20px;
    }

    .toolbar__h {
      font-size: 38px;
    }
  }
}

@media(max-width: 768px) {
  .unit-type-detail {
    .btn-download-brochure {
      margin: 0;
      width: 110px;
      height: 28px;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
      padding: 0;
      border-color: #D4B16F;
      color: #D4B16F;
      > img {
        margin-right: 7.5px;
      }
    }

    .ct-1390 {
      padding: 0 16px;
    }

    .previous-page {
      margin: 6px 0 0 0;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
      > img {
        height: 15px;
        vertical-align: -1px;
        margin-right: 5px;
      }
    }

    .section-floor-plan {
      height: 232px;
    }

    .section-floor-plan__loading {
      top: 106.5px;
    }

    .toolbar {
      padding: 20px 0 0 0;
    }

    .toolbar__h {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 32px;
      margin: 30px 0;
      display: block;
    }

    .unit-detail-list {
      margin-bottom: 31px;
      li {
       &:before {
         vertical-align: 2px;
       } 
      }
      
    }

    .unit-plan {
      margin-bottom: 50px;
    }
  }
}

@media screen and (min-width: 521px)
and (max-width: 768px)
and (orientation: portrait){
    .content-unit-detail{
        .img-cover_h180{
            height: 320px;
        }
    }
    .unit-type-detail{
      .slick-arrow{
        top: 50% !important;
        margin-top: -20px;
      }

      .section-floor-plan {
        height: 370px;
        .img-cover_h232 {
          height: inherit;
        }
      }
    }
}