.chosen-standard {
  width: 100% !important;
  display: block;
  height: 33px;
  border: none;
  border-bottom: 1px solid #979797;
  padding-bottom: 12px;

  &.chosen-with-drop {
    .chosen-single {
      div {
        b {
          background-position: 0px 4px;
          transform: rotate(180deg);
        }
      }
    }
  }

  .chosen-drop {
    margin-top: 1px;
    box-shadow: none;
    border-color: #979797;
    border-radius: 0 0 2px 2px;
  }

  .chosen-results {
    color: #737373; 
    color: rgba(#D4B16F, .85);
    margin: 0;
    padding: 0;
    li {
      padding: 10px;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      font-family: 'Forum', sans-serif;
      &.highlighted {
        background-image: none;
        background-color: #D4B16F;
        background-color: rgba(#D4B16F, .85);
        color: #fff;
      }
    }
  }

  .chosen-single {
    background-color: transparent;
    border: none;
    box-shadow: none;
    background-image: none;
    &.chosen-default {
      span {
        color: #D4B16F; 
        color: rgba(#D4B16F, .85); 
      }
    }

    div {
      b {
        transition: transform .3s ease;
        background: url(/images/contact/arrow-select.png) no-repeat 0px 4px;
      }
    }

    span {
      color: #979797; 
      font-family: 'Forum', sans-serif;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
    }

  }

  &.chosen-with-drop {
    .chosen-single {
      border: none;
      border-radius: 0;
      background-image: none;
      box-shadow: none;
    }
  }
}