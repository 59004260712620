/* ############ Font Awesome ############ */
@font-face {
  font-family: 'FontAwesome';
  src: url('/fonts/fontawesome/fontawesome-webfont.eot?v=4.5.0');
  src: url('/fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.5.0') format('embedded-opentype'), 
       url('/fonts/fontawesome/fontawesome-webfont.woff2?v=4.5.0') format('woff2'), 
       url('/fonts/fontawesome/fontawesome-webfont.woff?v=4.5.0') format('woff'), 
       url('/fonts/fontawesome/fontawesome-webfont.ttf?v=4.5.0') format('truetype'), 
       url('/fonts/fontawesome/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Material Design Icon */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/material-design-icons/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url('/fonts/material-design-icons/MaterialIcons-Regular.woff2') format('woff2'),
       url('/fonts/material-design-icons/MaterialIcons-Regular.woff') format('woff'),
       url('/fonts/material-design-icons/MaterialIcons-Regular.ttf') format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 16px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/* ############ Forum ############ */
@import url('https://fonts.googleapis.com/css?family=Forum');

/* ############ Playfair Display ############ */
@font-face {
    font-family: 'playfair_bold';
    src: url('/fonts/playfairdisplay/playfairdisplay-bold.eot');
    src: url('/fonts/playfairdisplay/playfairdisplay-bold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/playfairdisplay/playfairdisplay-bold.woff2') format('woff2'),
         url('/fonts/playfairdisplay/playfairdisplay-bold.woff') format('woff'),
         url('/fonts/playfairdisplay/playfairdisplay-bold.ttf') format('truetype'),
         url('/fonts/playfairdisplay/playfairdisplay-bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'playfair_bold_italic';
    src: url('/fonts/playfairdisplay/playfairdisplay-bolditalic.eot');
    src: url('/fonts/playfairdisplay/playfairdisplay-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/playfairdisplay/playfairdisplay-bolditalic.woff2') format('woff2'),
         url('/fonts/playfairdisplay/playfairdisplay-bolditalic.woff') format('woff'),
         url('/fonts/playfairdisplay/playfairdisplay-bolditalic.ttf') format('truetype'),
         url('/fonts/playfairdisplay/playfairdisplay-bolditalic.svg') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'playfair_italic';
    src: url('/fonts/playfairdisplay/playfairdisplay-italic.eot');
    src: url('/fonts/playfairdisplay/playfairdisplay-italic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/playfairdisplay/playfairdisplay-italic.woff2') format('woff2'),
         url('/fonts/playfairdisplay/playfairdisplay-italic.woff') format('woff'),
         url('/fonts/playfairdisplay/playfairdisplay-italic.ttf') format('truetype'),
         url('/fonts/playfairdisplay/playfairdisplay-italic.svg') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'playfair_regular';
    src: url('/fonts/playfairdisplay/playfairdisplay-regular.eot');
    src: url('/fonts/playfairdisplay/playfairdisplay-regular.eot?#iefix') format('embedded-opentype'),
         url('/fonts/playfairdisplay/playfairdisplay-regular.woff2') format('woff2'),
         url('/fonts/playfairdisplay/playfairdisplay-regular.woff') format('woff'),
         url('/fonts/playfairdisplay/playfairdisplay-regular.ttf') format('truetype'),
         url('/fonts/playfairdisplay/playfairdisplay-regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'playfair_black_italic';
    src: url('/fonts/playfairdisplay/playfairdisplay-blackitalic.eot');
    src: url('/fonts/playfairdisplay/playfairdisplay-blackitalic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/playfairdisplay/playfairdisplay-blackitalic.woff2') format('woff2'),
         url('/fonts/playfairdisplay/playfairdisplay-blackitalic.woff') format('woff'),
         url('/fonts/playfairdisplay/playfairdisplay-blackitalic.ttf') format('truetype'),
         url('/fonts/playfairdisplay/playfairdisplay-blackitalic.svg') format('svg');
    font-weight: normal;
    font-style: normal;

}

/* ############ Forum ############ */
@font-face {
    font-family: 'Forum';
    src: url('/fonts/forum/Forum.eot');
    src: url('/fonts/forum/Forum.eot?#iefix') format('embedded-opentype'),
         url('/fonts/forum/Forum.woff2') format('woff2'),
         url('/fonts/forum/Forum.woff') format('woff'),
         url('/fonts/forum/Forum.ttf') format('truetype'),
         url('/fonts/forum/Forum.svg') format('svg');
    font-weight: normal;
    font-style: normal;

}

/* ############ Quattrocento ############ */
@font-face {
    font-family: 'Quattrocento-Regular';
    src: url('/fonts/quattrocento/QuattrocentoSans-Regular.eot');
    src: url('/fonts/quattrocento/QuattrocentoSans-Regular.eot?#iefix') format('embedded-opentype'),
         url('/fonts/quattrocento/QuattrocentoSans-Regular.woff2') format('woff2'),
         url('/fonts/quattrocento/QuattrocentoSans-Regular.woff') format('woff'),
         url('/fonts/quattrocento/QuattrocentoSans-Regular.ttf') format('truetype'),
         url('/fonts/quattrocento/QuattrocentoSans-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;

}


/* ############ Raleway ############ */
@font-face {
    font-family: 'Raleway-Light';
    src: url('/fonts/raleway/Raleway-Light.eot');
    src: url('/fonts/raleway/Raleway-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/raleway/Raleway-Light.woff') format('woff'),
        url('/fonts/raleway/Raleway-Light.ttf') format('truetype'),
        url('/fonts/raleway/Raleway-Light.svg') format('svg');
    font-weight: normal;
}

@font-face {
    font-family: 'Raleway-Regular';
    src: url('/fonts/raleway/Raleway-Regular.eot');
    src: url('/fonts/raleway/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/raleway/Raleway-Regular.woff') format('woff'),
        url('/fonts/raleway/Raleway-Regular.ttf') format('truetype'),
        url('/fonts/raleway/Raleway-Regular.svg') format('svg');
    font-weight: normal;
}

@font-face {
    font-family: 'Raleway-Medium';
    src: url('/fonts/raleway/Raleway-Medium.eot');
    src: url('/fonts/raleway/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/raleway/Raleway-Medium.woff') format('woff'),
        url('/fonts/raleway/Raleway-Medium.ttf') format('truetype'),
        url('/fonts/raleway/Raleway-Medium.svg') format('svg');
}

@font-face {
    font-family: 'Raleway-Bold';
    src: url('/fonts/raleway/Raleway-Bold.eot');
    src: url('/fonts/raleway/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/raleway/Raleway-Bold.woff') format('woff'),
        url('/fonts/raleway/Raleway-Bold.ttf') format('truetype'),
        url('/fonts/raleway/Raleway-Bold.svg') format('svg');
}

@font-face {
    font-family: 'Raleway-SemiBold';
    src: url('/fonts/raleway/Raleway-SemiBold.eot');
    src: url('/fonts/raleway/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/raleway/Raleway-SemiBold.woff') format('woff'),
        url('/fonts/raleway/Raleway-SemiBold.ttf') format('truetype'),
        url('/fonts/raleway/Raleway-SemiBold.svg') format('svg');
}

@font-face {
    font-family: 'Raleway-Italic';
    src: url('/fonts/raleway/Raleway-Italic.eot');
    src: url('/fonts/raleway/Raleway-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/raleway/Raleway-Italic.woff') format('woff'),
        url('/fonts/raleway/Raleway-Italic.ttf') format('truetype'),
        url('/fonts/raleway/Raleway-Italic.svg') format('svg');
}


/* ############ Open Sans ############ */
@font-face {
    font-family: 'opensans-italic';
    src: url('/fonts/opensans/opensans-italic.eot');
    src: url('/fonts/opensans/opensans-italic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-italic.woff') format('woff'),
         url('/fonts/opensans/opensans-italic.svg#opensans-italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-regular';
    src: url('/fonts/opensans/opensans-regular.eot');
    src: url('/fonts/opensans/opensans-regular.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-regular.woff') format('woff'),
         url('/fonts/opensans/opensans-regular.svg#opensans-regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-semibold';
    src: url('/fonts/opensans/opensans-semibold.eot');
    src: url('/fonts/opensans/opensans-semibold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-semibold.woff') format('woff'),
         url('/fonts/opensans/opensans-semibold.svg#opensans-semibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-bold';
    src: url('/fonts/opensans/opensans-bold.eot');
    src: url('/fonts/opensans/opensans-bold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-bold.woff') format('woff'),
         url('/fonts/opensans/opensans-bold.svg#opensans-bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-extrabold';
    src: url('/fonts/opensans/opensans-extrabold.eot');
    src: url('/fonts/opensans/opensans-extrabold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-extrabold.woff') format('woff'),
         url('/fonts/opensans/opensans-extrabold.svg#opensans-extrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-semibold_italic';
    src: url('/fonts/opensans/opensans-semibolditalic.eot');
    src: url('/fonts/opensans/opensans-semibolditalic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-semibolditalic.woff') format('woff'),
         url('/fonts/opensans/opensans-semibolditalic.svg#opensans-semibold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-bold_italic';
    src: url('/fonts/opensans/opensans-bolditalic.eot');
    src: url('/fonts/opensans/opensans-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-bolditalic.woff') format('woff'),
         url('/fonts/opensans/opensans-bolditalic.svg#opensansbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-extrabold_italic';
    src: url('/fonts/opensans/opensans-extrabolditalic.eot');
    src: url('/fonts/opensans/opensans-extrabolditalic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-extrabolditalic.woff') format('woff'),
         url('/fonts/opensans/opensans-extrabolditalic.svg#opensans-extrabold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-light';
    src: url('/fonts/opensans/opensans-light.eot');
    src: url('/fonts/opensans/opensans-light.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-light.woff') format('woff'),
         url('/fonts/opensans/opensans-light.svg#opensans-light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-light_italic';
    src: url('/fonts/opensans/opensans-lightitalic.eot');
    src: url('/fonts/opensans/opensans-lightitalic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-lightitalic.woff') format('woff'),
         url('/fonts/opensans/opensans-lightitalic.svg#opensans-light_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}