 .unit-type-detail { 
  .montase-dropdown {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 150;
    &.open {
      .montase-dropdown__btn {
        > img {
          transform: rotate(180deg);
        }
      }
    }
  }
  
  .montase-dropdown__btn {
    width: 170px;
    display: flex;
    height: 45px;
    align-items: center;
    justify-content: center;
    background-color: #C69D51;
    color: #fff;
    border: 1px solid transparent;
    &:hover {
      background-color: darken(#C69D51, 10%);
    }
    > img {
      margin-left: 8px;
      transition: transform .3s ease; 
    }
  }

  .montase-dropdown__menu {
    min-width: 165px;
    li {
      padding: 8px 10px;
      &:hover {
        color: #C69D51;
      }
    }
    .active {
      background-color: #C69D51;
      color: #FFFFFF;
      &:hover {
        color: #FFFFFF;
      }
    }
  }
}

@media(max-width: 768px) {
  .unit-type-detail {
    .montase-dropdown__btn {
      width: 108px;
      height: 28px;
      font-size: 14px;
      line-height: 16px;
      padding: 0;
      > img {
        margin-left: 6px;
      }
    }

    .montase-dropdown__menu {
      min-width: 108px;
      li {
        padding: 5px 12px;
      }
    }
  }
}