.page--facilities {

  .card-gallery {
    position: relative;
    height: 420px;
    width: 100%;
  }

  .card-gallery__thumb {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 10;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }

  .card-horizontal {
    position: relative;
    background-color: #D8D8D8;
    height: 420px;
    width: 100%;
    &.reverse {
      .card-horizontal__content {
        right: auto;
        left: 0;
      }
      .card-horizontal__img {
        position: absolute;
        z-index: 10;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  .card-horizontal__content {
    width: 480px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: #1A1A1A;
    padding: 30px 40px 59px 40px;
  }

  .card-horizontal__description {
    color: #FFFFFF;
    p {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 30px;
      span{
        font: inherit !important; 
        color: inherit !important;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      color: #fff;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 25px;
      width: 100%;
      display: block;
      &:after {
        clear: both;
      }
      li {
        padding-left: 10px;
        position: relative;
        &:before {
          content: "-";
          position: absolute;
          left: 0;
        }
        span{
          font-family: inherit !important;
          font-size: inherit !important;
          color: inherit !important;
        }
      }
    }
  }

  .card-horizontal__img {
    width: 800px;
  }

  .card-horizontal__h {
    color: #CCA533;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 48px;
    margin-bottom: 30px;
  }

  .card-horizontal__list {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
    width: 100%;
    display: block;
    &:after {
      clear: both;
    }
    li {
      display: block;
      &:before {
        content: "-";
        display: inline-block;
        margin-right: 3px;
      }
    }
  }

  .card-wrapper {
    .facilities-item {
      &:last-child {
        margin-bottom: 80px;
      }
    }
  }

  .content-facilities {
    padding-top: 70px;
    span{
      font: inherit !important; 
      color: inherit !important;
    }
  }

  .facilites-copyright{
    p {
      font-size: 18px;
      line-height: 25px;
      span{
        font: inherit !important; 
        color: inherit !important;
      }
    }
  }

  .facilities-item {
    margin-bottom: 30px;
  }

  .heading{}

  .line-tpr {
    margin-bottom: 68px;
  }

}

@media(max-width: 1340px) {
  .page--facilities {
    .card-horizontal {
      &.reverse {
        .card-horizontal__img {
          left: 480px;
          right: 0;
        }
      }
    }

    .card-horizontal__img {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 10;
      right: 480px;
      width: auto;
    }
  }
}

@media(max-width: 1024px) {
  .page--facilities {
    .card-horizontal {
      &.reverse {
        .card-horizontal__img {
          left: 400px;
          right: 0;
        }
      }
    }

    .card-horizontal__content {
      width: 400px;
      padding: 20px 20px 30px 20px;
    }

    .card-horizontal__img {
      right: 400px;
    }

    .card-horizontal__h {
      margin-bottom: 20px;
    }

    .card-horizontal__description {
      p {
        margin-bottom: 20px;
      }
    }
  }
}


@media(max-width: 991px) {
  .page--facilities {
    .card-horizontal, .card-horizontal__img, 
    .card-horizontal__content, .card-gallery, .card-gallery__thumb {
      height: 360px;
    }

    .card-horizontal__content {
      padding: 30px 20px;
    }

    .card-horizontal__description {
      p {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 22px;
      }

      ul {
        font-size: 16px;
        line-height: 24px;
      }

    }

    .card-horizontal__h {
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 15px;
    }
  }
}

@media(max-width: 768px) {
  .page--facilities {
    .content-facilities {
      padding: 30px 16px 0 16px;
    }

    .facilities-collapse {
      background-color: #000000;
      color: #ffffff;
      padding: 20px 16px;
      &.open {
        .facilities-collapse__h {
          &:after {
            transform: rotate(0deg);
          }
        }
      }
    }

    .facilities-collapse__content { 
      transition: height .3s ease;
      p {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 23px;
        margin-bottom: 27px;
        span{
          font: inherit !important; 
          color: inherit !important;
        }
      }
    }

    .facilities-collapse__h {
      position: relative;
      color: #CCA533;
      padding-right: 10px;
      &:after {
        content: " ";
        position: absolute;
        right: 0;
        top: 9px;
        background-image: url('/images/facilities/collapse-icon.png');
        height: 9px;
        width: 16px;
        background-size: cover;
        background-position: center;
        transform: rotate(180deg);
        transition: transform .3s ease;
      }
    }

    .facilities-collapse__header{}

    .facilities-collapse__list {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
      margin: 0;
      padding-left: 16px;
      li {
        margin-bottom: 10px;
        list-style-type: disc;
        text-indent: -7px;
      }
    }

    .facilities-item-mobile {
      margin-left: -16px;
      margin-right: -16px;
      &:last-child{
        margin-bottom: 0;
      }
    }

    .line-tpr {
      margin-bottom: 30px;
    }
  }
}

@media only screen and (min-width: 521px)
and (max-width: 768px){
  .facilities-item-mobile{
    .img-cover_h180{
      height: 320px;
    }
  }
}