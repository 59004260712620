.mfp-fade.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}



.mfp-fade.mfp-bg.mfp-ready{
  opacity:1;
}

.mfp-bottom-bar{
  top: 0;
  margin-top: -30px;
  .mfp-title {
    text-align: center;
    color: #fff;
    padding-right: 0;
    font-size: 18px;
    font-family: 'playfair_regular';
  }
}

.mfp-close-custom{
  width: auto;
  height: auto;
  cursor: pointer;
  font-size: 18px;
  color: #C69D51;
  letter-spacing: 0;
  border: 2px solid #C69D51;
  border-radius: 2px;
  line-height: 21px;
  padding: 10px 15px;
  top: 40px;
  right: 40px;
  span{
    font-size: 28px;
    line-height: 0px;
    position: relative;
    top: 3px;
  }
  &:active{
    top: 40px;
  }
}

.mfp-arrow {
  width: 50px;
  height: 50px;
  opacity: 1;
  margin-top: -25px;
  background-color: rgba(255,255,255,.5) !important;
  &:before{display: none;}
  &:after{
    // reset style default
    border-right: 0;
    border-bottom-style: initial;

    width: 13px;
    height: 13px;
    border-top: 2px solid #000;
    border-left: 2px solid #000;
    position: absolute;
    top:0;right:0;bottom:0;left:0;
    margin:auto;
  
  }
  &:active{
    margin-top: -25px;
    background-color: rgba(255,255,255,1) !important;
  }
  &:hover{
    background-color: rgba(255,255,255,1) !important;
  }

  &.mfp-arrow-left{
    &:after{
      transform: rotate(-45deg);
    }
  }
  &.mfp-arrow-right{
    &:after{
      transform: rotate(135deg);
    }
  }
}

@media screen and (max-width: 520px){
  .mfp-close-custom{
    font-size: 14px;
    line-height: 16px;
    padding: 2px 10px;
    top:26px;
    right:16px;
    span{display:none;}
  }
  .mfp-container {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-bottom-bar{margin-top:-15px;}
}