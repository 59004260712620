.header{
	.navbar{
		.navbar__li{
			&:last-child{
				margin-right:0;
			}
			.navbar__a{
				letter-spacing: 1.2px;
				color: #C69D51;
				transition: .2s ease;
				&:hover{
					color: darken(#C69D51, 5%);
				}
			}

			&.contact{
				.navbar__a{
					padding: 10px 23px;
					color: #FFFFFF;
					background: rgba(202,163,92,0.80);
					border-radius: 2px;
					&:hover{
						background: rgba(202,163,92,1);
						color: #fff;
					}
				}
			}
		}
	}
}

.footer{
	.navbar{
		.navbar__li{
			.navbar__a{
				font-size: 16px;
				color: rgba(198, 157, 81, .7);
				letter-spacing: 0.5px;
				&:hover{
					&:hover{
						color: darken(#C69D51, 5%);
					}
				}
			}
		}
	}

	.lang__bar{
		.lang__bar__li{
			border-right: 1px solid rgba(226, 226, 226, .4);
			&:last-child{border-right: 0;}
			&.active,
			&:hover{
				.lang__bar__a{
					color: rgba(129, 129, 129, .8);
				}    
			}
			.lang__bar__a{
				color: rgba(225, 225, 225, .3);
			}
		}
	}
}

.navbar{
	display: flex;
	align-items:center;
	.navbar__li{
		margin: 0 13px;
	}
}

.m--navbar__dropdown{
	background-color: #fff;
	height: 100vh;

	display: none;
	.m--navbar__ul{
		.m--navbar__li{
			.m--navbar__a{
				padding: 10px 16px;
				color: #D4B16F;
				display: block;
				border-top: 1px solid #d2d2d2;
			}
			&:last-child{
				.m--navbar__a{
					border-bottom: 1px solid #d2d2d2;
				}				
			}
		}
	}
}

.m--navbar{
	.contact{
		opacity:1;
		visibility: visible;
		transition: .3s ease;
	}
}

.show-dropdown-navbar{
	overflow: hidden;
	.m--navbar__dropdown{
		display: block;
	}
	.m--navbar{
		.contact{
			opacity:0;
			visibility: hidden;
		}
	}
}