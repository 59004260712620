.row{
	@include clearfix;
	.col{
		float: left;
		min-height: 1px;
		&.m1{width: susy-span(1 of 12);}
		&.m2{width: susy-span(2 of 12);}
		&.m3{width: susy-span(3 of 12);}
		&.m4{width: susy-span(4 of 12);}
		&.m5{width: susy-span(5 of 12);}
		&.m6{width: susy-span(6 of 12);}
		&.m7{width: susy-span(7 of 12);}
		&.m8{width: susy-span(8 of 12);}
		&.m9{width: susy-span(9 of 12);}
		&.m10{width: susy-span(10 of 12);}
		&.m11{width: susy-span(11 of 12);}
		&.m12{width: susy-span(12 of 12);}
	}
}

.row-gutter{
  @include clearfix;
  .col{
    float: left;
    min-height: 1px;
    margin-right: gutter(of 12);
    &:last-child{
      margin-right: 0;
    }
    &.m1{width: susy-span(1 narrow of 12 narrow);}
    &.m2{width: susy-span(2 narrow of 12 narrow);}
    &.m3{width: susy-span(3 narrow of 12 narrow);}
    &.m4{width: susy-span(4 narrow of 12 narrow);}
    &.m5{width: susy-span(5 narrow of 12 narrow);}
    &.m6{width: susy-span(6 narrow of 12 narrow);}
    &.m7{width: susy-span(7 narrow of 12 narrow);}
    &.m8{width: susy-span(8 narrow of 12 narrow);}
    &.m9{width: susy-span(9 narrow of 12 narrow);}
    &.m10{width: susy-span(10 narrow of 12 narrow);}
    &.m11{width: susy-span(11 narrow of 12 narrow);}
    &.m12{width: susy-span(12 narrow of 12 narrow);}
  }
}

@media(max-width: 1023px) {
  .row{
    @include clearfix;
    .col{
      float: left;
      min-height: 1px;
      &.s1{width: susy-span(1 of 12);}
      &.s2{width: susy-span(2 of 12);}
      &.s3{width: susy-span(3 of 12);}
      &.s4{width: susy-span(4 of 12);}
      &.s5{width: susy-span(5 of 12);}
      &.s6{width: susy-span(6 of 12);}
      &.s7{width: susy-span(7 of 12);}
      &.s8{width: susy-span(8 of 12);}
      &.s9{width: susy-span(9 of 12);}
      &.s10{width: susy-span(10 of 12);}
      &.s11{width: susy-span(11 of 12);}
      &.s12{width: susy-span(12 of 12);}
    }
  }
}

@media(max-width: 768px) {
  .row{
    @include clearfix;
    .col{
      float: left;
      min-height: 1px;
      &.xs1{width: susy-span(1 of 12);}
      &.xs2{width: susy-span(2 of 12);}
      &.xs3{width: susy-span(3 of 12);}
      &.xs4{width: susy-span(4 of 12);}
      &.xs5{width: susy-span(5 of 12);}
      &.xs6{width: susy-span(6 of 12);}
      &.xs7{width: susy-span(7 of 12);}
      &.xs8{width: susy-span(8 of 12);}
      &.xs9{width: susy-span(9 of 12);}
      &.xs10{width: susy-span(10 of 12);}
      &.xs11{width: susy-span(11 of 12);}
      &.xs12{width: susy-span(12 of 12);}
    }
  }
}

.row_gutter-5 {
  margin-left: -5px;
  margin-right: -5px;
}

.col_gutter-5 {
  padding-left: 5px;
  padding-right: 5px; 
}

.row_gutter-20 {
  margin-left: -20px;
  margin-right: -20px;
}

.col_gutter-20 {
  padding-left: 20px;
  padding-right: 20px; 
}

.row_gutter-50 {
  margin-left: -50px;
  margin-right: -50px;
}

.col_gutter-50 {
  padding-left: 50px;
  padding-right: 50px; 
}

@media(max-width: 768px) {
  .xs-row_gutter-16 {
    margin-left: -16px;
    margin-right: -16px;
  }

  .xs-row_gutter-40 {
    margin-left: -40px;
    margin-right: -40px;
  }
}