.unit-type-detail {
  .ct-nav-tabs {
    position: relative;
    &:before, &:after {
      content: " ";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #E7E5E2;
      background-color: rgba(#4C4C4C, .25);
      position: absolute;
      left: 0;
      right: 0;
      z-index: 11;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 4px;
    }
  }

  .nav-tabs {
    display: block;
    text-align: center;
    width: 460px;
    margin: 0 auto;
  }

  .tabs-material {
    display: inline-block;
    &:before, &:after {
      content: " ";
      display: table;
      zoom: 1;
    }
    &:after {
      clear: both;
    }
    .tabs-material__list {
      padding: 10px 70px 12px 0;
      &:last-child{
        padding-right: 0;
      }
      &.active {
        > a {
          color: #CDA966;
        }
      }
      > a {
        font-size: 24px;
        letter-spacing: 0;
        line-height: 29px;
        color: #818181;
        opacity: 1;
      }
    }
  }

  .tabs-material-line {
    background-color: #CDA966;;
    height: 2px;
  }

  .tabs-material-content {
    padding-top: 26px;
    .tabs-material-item {
      display: block;
      height: 0;
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
      transition: opacity .3s ease;
      &.active {
        height: auto;
        overflow: auto;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

@media(max-width: 768px) {
  .unit-type-detail {
    .tabs-material {
      .tabs-material__list {
        padding: 8px 42px 11px 0;
        &:last-child {
          padding-right: 0;
        }
        > a {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    .tabs-material-content {
      .tabs-material-item {
        .ct-960 {
          padding: 0;
        }
      }
    }
  }
}

@media(max-width: 460px) {
  .unit-type-detail {
    .nav-tabs {
      width: 100%;
    }
  }
}