input[type='submit'],
button{ cursor:pointer; }
a,
input[type='submit'],
button{
	position:relative;
	display: inline-block;
	border: none; outline: none; 
	text-decoration:none;
	transition: .3s ease;
	&.btn--primary{
		font-size: 18px;
		color: #fff;
		letter-spacing: 0;
		background-color: #D4B16F;
		border-radius: 2px;
		padding: 11px 30px;
		&:hover{
			background-color: darken(#D4B16F, 5%);
		}
	}
  &.loading{
    &:after{
      content:'';
      position:absolute;
      top:0; bottom:0; left:0; right:0;
      margin:auto;
      width:100%; height:100%;
      background: rgba(255,255,255,0.8) url(/images/spinner.svg) no-repeat center center;
      z-index:2;
    }
    // &:before{
    //   content:'';
    //   position:absolute;
    //   top:0; bottom:0; left:0; right:0;
    //   margin:auto;
    //   border-radius:4px;
    //   background:rgba(255,255,255,0.8);
    //   z-index:1;
    // }
  }
}
.btn {
  display: inline-block;
  padding: 8px 14px;
  box-shadow: none;
  background-color: #ddd;
  color: #414141;
  border-radius: 2px;
  color: #fff;
  transition: all .3s ease;
  border: 1px solid #f5f5f5;
  outline: none;
  &:hover {
    background-color: darken(#ddd, 10%);
  }
}

.btn-primary{
  min-width: 190px;
  height: 43px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  background-color: #D4B16F;
  color: #fff;
  padding: 11px 20px;
  &:hover {
    background-color: darken(#D4B16F, 10%);
  }
}

@media(max-width: 768px) {
  input[type='submit'],
  button{
    &.btn--primary{
      padding: 9px 30px;
    }
  }
  .btn-primary {
    min-width: 157px;
    height: 35px;
    padding: 0 12px;
  }
}