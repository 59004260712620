.unit-type-detail {
  .slick-arrow {
    height: 50px;
    width: 50px;
    background-color: #fff;
    background-color: rgba(#fff, .5);
    border: none;
    position: absolute;
    outline: none;
    overflow: hidden;
    top: 215px;
    bottom: auto;
    z-index: 101;
    color: transparent;
    transform: none;
    transition: all .3s ease;
    &:before {
      content: " ";
      margin: 0 auto;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 9px;
      height: 21px;
      display: block;
    }
    &:hover {
      background-color: #ffffff;
    }
  }

  .slick-dots {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 17px;
    margin: 0;
    padding: 0;
    text-align: center;
    z-index: 140;
    li {
      display: inline-block;
      margin-right: 6px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: #fff;
      background-color: rgba(#fff, .5);
      border: none;
      cursor: pointer;
      &.slick-active {
        background-color: #fff;
      }
      &:last-child {
        margin-right: 0;
      }
      > button {
        display: none;
      }
    }
  }

  .slick-next {
    right: 0;
    &:before {
      background-image: url("/images/unit-detail/chevron-right.png");
    }
  }

  .slick-prev {
    left: 0;
    &:before {
      background-image: url("/images/unit-detail/chevron-left.png");
    }
  }
}

@media(max-width: 768px) {
  .unit-type-detail {
    .slick-arrow {
      top: 72.5px;
      height: 35px;
      width: 35px;
      background-color: rgba(#fff, .5);
      &:before {
        width: 7px;
        height: 15px;
      }
      &:hover, &:focus {
        background-color: #ffffff;
      }
    }

    .slick-dots {
      bottom: 9px;
      li {
        height: 6px;
        width: 6px;
        margin-right: 4px;
      }
    }

    .slick-next {
      &:before {
        background-image: url(/images/unit-detail/chevron-right-mobile.png);
      }
    }

    .slick-prev {
      &:before {
        background-image: url(/images/unit-detail/chevron-left-mobile.png);
      }
    }
  }
}