.page--contact {
  .address {
    background-color: #F2F2F2;
    padding: 50px 30px;
    font-family: 'Forum', sans-serif;
    h4 {
      font-size: 20px;
      letter-spacing: 1.88px;
      line-height: 21px;
      color: #000;
      font-family: inherit;
    }

    p {
      font-size: 20px;
      letter-spacing: 0;
      line-height: 22px;
      color: #4B4B4B;
    }
  }

  .container-contact {
    padding-top: 70px;
  }

  .heading {
    margin-bottom: 70px;
  }

  .line-tpr {
    margin: 0 0 70px 0;
  }
}

@media(max-width: 768px) {
  .page--contact {
    .address {
      width: auto;
      margin-left: -16px;
      margin-right: -16px;
      margin-bottom: 50px;
      padding: 27px 28px;
      h4 {
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0;
        margin-bottom: 30px;
      }

      p {
        font-size: 18px;
        line-height: 19px;
      }
    }

    .container-contact {
      padding: 30px 16px 0 16px;
    }

    .form-contact {
      padding-left: 24px;
      padding-right: 24px;
    }

    .form__footer {
      margin-top: 0;
      margin-bottom: 50px;
    }

    .heading {
      margin-bottom: 28px;
    }

    .line-tpr {
      margin-bottom: 50px;
    }
  }
}