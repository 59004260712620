.alert {
  border: 1px solid #68DC6C;
  padding: 17px 27px;
  display: inline-block;
  border-radius: 2px;
}

.alert__body {
  padding-left: 28px;
}

.alert__icon {
  float: left;
  width: 19px;
  display: inline-block;
}

.alert__message {
  color: #717171;
  color: rgba(#0E0E0E, .5);
}

.alert-danger {
  border-color: transparent;
  background-color: #fbeceb;
}

.alert-success {
  border-color: transparent;
  background-color: #f6f6f6;
}