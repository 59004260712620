.page--home{
	.gold{color: #cca533;}

	$height: calc(100vh - 102px);
	.main--banner{
		width: 100%;
		height: calc(100vh - 90px);
		position: relative;
		.img{
			width: 100%;
			height:: 100%;
			position: absolute;
			top: 0;left:0;
			&:after{
				content: '';
				width: 100%;height:100%;
				position: absolute;
				top:0;left:0;
				background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.50) 100%);
			}
			img{
				position: absolute;
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}
		.text{
			position: absolute;
			bottom: 20%;
			left: 52px;
			color: #fff;
			max-width: 60%;
			h1{font-size: 64px !important;}
		}
		.scroll__down{
			position:absolute;
			bottom: 20px;
			left:0;right:0;
			margin: auto;
			max-width: 130px;
			text-align: center;
			color: #D4B16F;
		}
		
		.banner__carousel,
		.slick-track,
		.slick-list,
		.slick-slide{height: 100%;overflow: hidden;}

		.banner__control{
			position: absolute;
			bottom: 20%;
			right: 50px;
			width: 50%;
			text-align: right;
			.custom-dots{
				margin: 15px 0;
				li{
					position: relative;
					display: inline-block;
					margin: 0 5px;
					padding: 0;
					width: 50px;
					height: 2px;
					a{
						font-size: 0;
						line-height: 0;
						display: block;
						height: 100%;
						width: 100%;
						border-radius: 2px;
						background-color: rgba(255,255,255,.35);
						cursor: pointer;
						position: relative;
						pointer-events: none;
					}
					.slider-progress{
						height: 100%;
						width: 100%;
						background-color: rgba(255,255,255,.35);
						position: absolute;
						top:0;left:0;

						display: none;
						.progress{
							width: 0%;
							height: 100%;
							background-color: rgba(255,255,255,1);
						}
					}
					&.slick-active{
						a{background-color: transparent;}
						.slider-progress{display: block;}
					}
				}
			}
		}
	}

	.location{
		height: $height;
		.row,
		.col{
			height: 100%;
		}
		.location__about{
			height: 100%;
			width: 100%;
			padding: 70px 50px;
			h1{
				margin-bottom: 40px;
			}
			.location__about__p{
				p{
					margin-bottom: 20px;
					font-size: 18px;
					line-height: 25px;
					span{
						font: inherit !important; 
						color: inherit !important;
					}
				}
			}
			.btn--seemore{
				color: #D4B16F;
				letter-spacing: 0;
				position: relative;
				padding-right: 25px;
				margin-top: 70px;
				&:before{
					content: '';
					height: 1px;
					width: 50px;
					background-color: #D4B16F;
					position:absolute;
					left: -65px;
					top:-3px;bottom:0;
					margin: auto;
					pointer-events: none;
				}
				&:after{
					content: '';
					background-image: url(/images/icon/ico-arrowright.svg);
					width: 14px;
					height: 14px;
					position: absolute;
					top:-2px;bottom:0;right:0;
					margin: auto;
				}

				&:hover{
					color: darken(#D4B16F, 5%);	
				}
			}
		}
		.location__img{
			height: 100%;
			width: 100%;
			position: relative;
			img{
				position: absolute;
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}
	}

	.unit{
		height: $height;
		.content{
			color: #FFFFFF;
			position: relative;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			align-items: center;
			h1{
				font-size: 48px;
				letter-spacing: 0;
				margin-bottom: 40px;
			}
			h5{
				letter-spacing: 0;
				line-height: 25px;
			}
			.btn--exploreunit{
				font-size: 24px;
				letter-spacing: 0;
				margin-top: 100px;
				padding: 15px 30px;
				border-radius: 2px;
				position: relative;
				display: block;
				overflow: hidden;
				span{
					position: relative;
					z-index: 1;
					transition: 0.3s ease;
				}

				$btn-color: #D4B16F;
				$btn-color-dark: shade($btn-color, 40%);
				color: tint($btn-color);
				border: 2px solid currentColor;

				&:before,
				&:after {
					content: '';
					@include absolute(50%, null, null, null);
					@include size(20px);
					background-color: $btn-color;
					border-radius: 50%;
				}

				&:before {
					left: -20px;
					transform: translate(-50%, -50%);
				}

				&:after {
					right: -20px;
					transform: translate(50%, -50%);
				}

				&:hover {
					color: #fff;
					border: 2px solid $btn-color;

					span{
						transition: 0.5s ease-in-out;
					}

					&:before,
					&:after {

					}

					&:before {
						animation: criss-cross-left 0.8s both;
						animation-direction: alternate;
					}

					&:after {
						animation: criss-cross-right 0.8s both;
						animation-direction: alternate;
					}
				}
			}
		}
		.img{
			width: 100%;
			height:: 100%;
			position: absolute;
			top: 0;left:0;
			z-index: 0;
			&:after{
				content: '';
				width: 100%;height:100%;
				position: absolute;
				top:0;left:0;
				background-image: linear-gradient(-179deg, rgba(0,0,0,0.50) 0%, rgba(0,0,0,0.70) 100%);
			}
			img{
				position: absolute;
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}
	}

	.experience{
		margin-bottom: 30px;
		.content{
			padding-top:78px;
			padding-bottom: 38px; 
			width: 720px;
			margin: auto;
			padding-left: 0;
			padding-right: 0;
			max-width: 100%;
			h1{margin-bottom: 9px;}
			h3{font-size: 28px;}
		}
		[class*="container"]{overflow: hidden;}
		.experience__carousel{
			padding: 10px 0;
			margin-left: -100px;
			margin-right: -100px;
			background-color:#eeecea;
			.banner__slide{
				margin: 10px;
			}
			
			.slick-next,
			.slick-prev{
				height:100%;
				&:before{
					content:'';
					background: url(/images/icon/arrow-carousel.svg) no-repeat;
					width: 26px;
					height: 76px;
					position: absolute;
					top:0;bottom:0;
					margin: auto;
					z-index: 1;
				}
				&:after{
					content:'';
					width: 210px;
					height: 100%;
					position: absolute;
					top:0;bottom:0;
					margin: auto;
					pointer-events: none;
				}
			}
			.slick-prev {
				left: 0;
				z-index: 1;
				&:before{
					transform: rotate(180deg);
					left:125px;
				}
				&:after{
					left:100px;
					background-image: linear-gradient(270deg, rgba(238,236,234,0.00) 0%, rgba(238,236,234,0.75) 100%);
				}
			}
			.slick-next{
				right: 0;
				&:before{
					right:125px;
				}
				&:after{
					right:100px;
					background-image: linear-gradient(-270deg, rgba(238,236,234,0.00) 0%, rgba(238,236,234,0.75) 100%);
				}
			}
		}
	}

	.contactus{
		padding: 30px;
		margin-bottom: 20px;
		form .field{
			margin-bottom: 44px;
		}
		.form-error{
			::-webkit-input-placeholder { color: #D4776F; }
			::-moz-placeholder { color: #D4776F; } /* firefox 19+ */
			:-ms-input-placeholder { color: #D4776F; } /* ie */
			input:-moz-placeholder { color: #D4776F; }
			input{
				color: #D4776F;
			}
			p{
				font-size: 14px;
				line-height: 14px;
				letter-spacing: 0;
				margin-top: 7px;
				color: #D4776F;
			}
		}
		.row{
			.m6{
				width: susy-span(6 narrow of 12 narrow);
				margin-right: gutter(of 12);
				&:last-child{
					margin-right: 0;
				}
			}
		}

		.contactus__form{
			height: 610px;
			position: relative;
			h1{margin-bottom: 50px;font-size: 32px;}
			.contactus__detail{
				font-size: 16px;
				position: absolute;
				bottom: 0;
				left: 0;
				.contactus__name{
					color: #000000;
					letter-spacing: 0.7px;
					line-height: 21px;

					margin-bottom: 30px;
				}
				.contactus__address{
					color: #4B4B4B;
					letter-spacing: 0;
				}
				p{
					font-size: inherit;
				}
			}
			.js-form-response{
				.alert{
					margin-top: 30px;
					margin-bottom: 0;
				}
			}
		}
	}

	.brochure{
		background: #EEDCB3;
		padding: 20px;
		h4{
			color: #4A4A4A;
			letter-spacing: 0;
			margin-right: 20px;
		}
		.btn--download{
			background-color: #4B4B4B;
			border-radius: 2px;
			font-size: 18px;
			color: #EEDCB3;
			letter-spacing: 0;
			padding: 11px 40px;
			&:hover{
				background-color: darken(#4B4B4B, 5%);
			}
		}
	}
}

@media screen and (max-height: 719px)
and (orientation: landscape){
	.page--home{
		.location{
			height: auto !important;
		}
	}
}

@media screen and (max-width: 1280px){
	.page--home{
		.main--banner{
			.text{
				h1{
					font-size: 50px !important;
				}
			}
		}
		.location{
			 .location__about{
			 	.btn--seemore{
			 		margin-top: 30px;
			 	}
			 }
		}
		.unit{
			.content{
				.btn--exploreunit{
					font-size: 18px;
					padding: 12px 30px;
					margin-top: 50px;
					&:before,
					&:after {
						content: '';
						@include size(10px);
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px){
	.page--home{
		h1{font-size: 24px;}
		.main--banner{
			pointer-events: none;
			.text{
				h1{
					font-size: 46px !important;
				}
			}
		}
		.location{
			height: auto;
			.row{
				display: flex;
				flex-direction: column;
				.col{
					&.m6{
						width: susy-span(12 of 12);
					}
				}
			}
			.location__about{
				padding: 30px 16px;
				h1{margin-bottom: 30px;}
				.btn--seemore{margin-top:30px;}
				.location__about__p{
					p{
						font-size: 16px;
					}
				}
			}
			.location__img{
				height: 415px;
			}
		}
		.unit{
			height: auto;
			.content{
				padding-top: 90px;
				padding-bottom: 90px;
				h1{margin-bottom: 30px;font-size:24px;}
				h5{font-size: 16px;}
				.btn--exploreunit{
					font-size: 16px;
					padding: 10px 15px;
					margin-top: 50px;
					&:before,
					&:after{
						display: none;
					}
					&:hover{
						$btn-color: #D4B16F;
						$btn-color-dark: shade($btn-color, 40%);
						color: tint($btn-color);
						border: 2px solid currentColor;
					}
				}
			}
		}
		.experience{
			margin-bottom: 0;
			.content{
				padding: 30px 0;
				h3{
					font-size: 18px;
					padding: 0 10px;
				}
			}
		}
		.contactus{
			padding: 0;
			margin: 0;
			.row{
				display: flex;
				flex-direction: column-reverse;
				.col{
					float: none;
					&.m6{
						width: susy-span(12 of 12);
					}
				}
			}
			form{
				.field{
					margin-bottom: 20px;
				}
			}

			.contactus__form{
				padding: 20px;
				height:auto;
				h1{margin-bottom:28px;font-size: 24px;}
				.contactus__detail{
					position: relative;
					margin-top: 50px;
				}				
			}
			.map{
				height: 415px;
			}
		}
		.brochure{
			.content{
				display: block;
				padding:0;
				h4{
					margin-right:0;
					margin-bottom: 10px;
					font-size: 16px;
				}
				.btn--download{
					font-size: 16px;
					padding: 10px 20px;
				}
			}
		}
	}
}

@media screen and (max-width: 520px){
	.page--home{
		.main--banner{
			height: calc(100vh - 85px);
			.text{
				left: 0;
				bottom: 17%;
				max-width: 100%;
				padding: 0 17px;
				h1{font-size: 32px !important;}
			}
			.banner__control{
				right: 17px;
				bottom: 20px;
				.custom-dots{
					li{
						margin: 0 3px;
						width: 20px;
						border-radius: 2px;
					}
				}
			}
			.scroll__down{
				left: 17px;
				right: initial;
				h6{font-size: 11px;}
				i{
					width: 20px;
					height: 9px;
					background-size: 20px;
					margin: 1px 0 0;
				}
			}
		}
		.experience{
			.experience__carousel{
				margin-left: 0;
				margin-right: 0;
				.slick-next,
				.slick-prev{
					&:before{
						background: none;
						width: 8px;
						height: 8px;
						border-top: 2px solid #000;
						border-left: 2px solid #000;
						border-radius: 2px;
					}
					&:after{
						background-color: rgba(255,255,255,.7);
						background-image: none;
						width: 35px;
						height:35px;
					}
				}
				.slick-next{
					&:before{
						right:15px;
						transform: rotate(135deg);
					}
					&:after{
						right:0;
						border-top-left-radius: 2px;
						border-bottom-left-radius: 2px;
					}
				}
				.slick-prev{
					&:before{
						left:15px;
						transform: rotate(-45deg);
					}
					&:after{
						left:0;
						border-top-right-radius: 2px;
						border-bottom-right-radius: 2px;
					}
				}
			}
		}
	}
}


// mobile landscape only
@media screen and (max-width: 768px)
and (orientation: landscape){
	.page--home{
		.main--banner{
			height: calc(100vh - 90px);
			.text{
				left: 0;
				bottom: 17%;
				max-width: 100%;
				padding: 0 17px;
				h1{font-size: 32px;}
			}
			.banner__control{
				right: 17px;
				bottom: 20px;
				.custom-dots{
					li{
						margin: 0 3px;
						width: 20px;
						border-radius: 2px;
					}
				}
			}
			.scroll__down{
				left: 17px;
				right: initial;
				h6{font-size: 11px;}
				i{
					width: 20px;
					height: 9px;
					background-size: 20px;
					margin: 1px 0 0;
				}
			}
		}
		.location{
			.location__img{
				height: 300px;
			}
		}
		.experience{
			.experience__carousel{
				margin-left: 0;
				margin-right: 0;
				.slick-next,
				.slick-prev{
					&:before{
						background: none;
						width: 8px;
						height: 8px;
						border-top: 2px solid #000;
						border-left: 2px solid #000;
						border-radius: 2px;
					}
					&:after{
						background-color: rgba(255,255,255,.7);
						background-image: none;
						width: 35px;
						height:35px;
					}
				}
				.slick-next{
					&:before{
						right:15px;
						transform: rotate(135deg);
					}
					&:after{
						right:0;
						border-top-left-radius: 2px;
						border-bottom-left-radius: 2px;
					}
				}
				.slick-prev{
					&:before{
						left:15px;
						transform: rotate(-45deg);
					}
					&:after{
						left:0;
						border-top-right-radius: 2px;
						border-bottom-right-radius: 2px;
					}
				}
			}
		}
		.contactus{
			.map{
				height: 300px;
			}
		}
	}
}