.page--about {

  .about-us-heading {
    text-align: center;
    margin-bottom: 50px;
  }

  .content-about {
    margin-bottom: 50px;
  }

  .content-about__information {
    p {
      font-family: "Forum", sans-serif;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0;
      color: #646464;
      margin-bottom: 25px;
      text-align: justify;
      span{
        font: inherit !important; 
        color: inherit !important;
      }
    }
  }

  .primary-banner {
    margin-bottom: 70px;
  }

  .quotes {
    display: inline-block;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 27px;
    color: #C69D51;
    padding: 0 27px;
    position: relative;
    text-align: center;
    margin-bottom: 30px;
    &:before, &:after {
      content: " ";
      position: absolute;
      z-index: 9;
      background-size: cover;
      width: 24px;
      height: 17px;
    }
    &:before {
      top: 0px;
      left: 0px;
      background-image: url('/images/about/petik-kiri.png');
    }
    &:after {
      bottom: -4px;
      right: 0px;
      background-image: url('/images/about/petik-kanan.png');
    }
  }

}

@media(max-width: 768px) {
  .page--about {
    .about-us-heading {
      margin-bottom: 30px;
    }

    .content-about {
      margin-bottom: 0;
      .ct-1280 {
        padding: 0 16px;
      }
    }

    .content-about__information {
      p {
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 23px;
      }
    }

    .img-about {
      height: 415px;
    }

    .primary-banner {
      margin-bottom: 30px;
    }

    .quotes {
      font-size: 18px;
      line-height: 19px;
      padding: 0 25px;
      margin-bottom: 17px;
      &:before, &:after {
        width: 18px;
        height: 13px;
      }
    }
  }
}