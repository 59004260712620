.page--unit-type {
  
  .banner-placeholder {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:before {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 4;
      background-color: #000;
      background-color: rgba(0,0,0, 0.8);
    }
  }

  .banner-placeholder__btn {
    height: 50px;
    width: 270px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #D4B16F;
    color: #D4B16F;
    background-color: transparent;
    z-index: 10;
    &:hover {
      background-color: #D4B16F;
      color: #fff;
    }
  }

  .banner-placeholder__p {
    color: #fff;
    z-index: 10;
  }

  .btn-see-detail {
    width: 200px;
    padding: 13px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #D4B16F;
    position: absolute;
    right: 0;
    z-index: 9;
    height: 43px;
    letter-spacing: 1.5px;
    &:hover {
      background-color: darken(#D4B16F, 10%);
    }
  }

  .card-unit {
    margin-left: -5px;
    margin-right: -5px;
    &:before, &:after {
      display: table;
      zoom: 1;
      content: " ";
    }
    &:after {
      clear: both;
    }
  }

  .card-unit__gallery{
    height: 100%;
    .row{
      height: 100%;
      .col.m6{
        height: 50%;
        &:nth-child(1),
        &:nth-child(2){
          padding-bottom: 5px;
        }
        &:nth-child(3),
        &:nth-child(4){
          padding-top: 5px;
        }

        .card-unit__thumb{
          height: 100%;
        }
      }
    }
  }

  .card-unit__area {
    margin: 0px 0 0 0;
    padding: 0;
    display: inline-block;
    margin-left: -8px;
    margin-right: -8px;
    li {
      margin-left: -5px;
      display: inline-block;
      padding: 4px 13px;
      &:nth-child(2) {
        border-left: 1px solid #979797;
      }
      &:nth-child(3) {
        border-left: 1px solid #979797;
      }
    }
    span {
      display: block;
      margin-top: 5px;
      color: #4b4b4b;
      color: rgba(#4b4b4b, .8);
    }
  }

  .card-unit__col {
    position: relative;
    float: left;
    min-height: 1px;
    padding: 0 5px;
  }

  .card-unit__col_w550 {
    width: 550px;
  }

  .card-unit__col_w780 {
    width: 780px;
  }

  .card-unit__content {
    background-color: #f6f6f6;
    padding: 30px 30px 49px 30px;
  }

  .card-unit__ct-btn {
    height: 50px;
    position: relative;
  }

  .card-unit__description {
    p {
      text-align: justify;
      font-family: 'Forum', sans-serif;
      margin-bottom: 30px;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 25px;
      span{
        font: inherit !important; 
        color: inherit !important;
      }
    }

    ul {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 47px;
      li {
        padding-bottom: 13px;
        vertical-align: top;
        padding-left: 13px;
        position: relative;
        width: 100%;
        // &:nth-child(odd) {
        //   width: 58%;
        // }
        // &:nth-child(even) {
        //   width: 42%;
        // }
        &:before {
          content: " ";
          position: absolute;
          left: 0;
          top: 7px;
          display: inline-block;
          height: 6px;
          width: 6px;
          background-color: #4b4b4b;
          display: inline-block;
          border-radius: 50%;
        }

        span{
          font: inherit !important; 
          color: inherit !important;
        }
      }
    }
  }

  .card-unit__h {
    color: #4b4b4b;
    margin-bottom: 50px;
  }
  
  .content-unit-type {
    padding-top: 50px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .unit-type-heading {
    color: #4b4b4b;
  }


}

@media(max-width: 1400px) {
  .page--unit-type {
    .card-unit__col_w550 {
      width: 45%;
    }

    .card-unit__col_w780 {
      width: 55%;
    }
  }
}

@media(max-width: 1200px) {
  .page--unit-type {
    .card-unit__h {
      margin-bottom: 40px;
    }

    .card-unit__description {
      p {
        margin-bottom: 20px;
      }
      ul {
        margin-bottom: 37px;
        li {
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media(max-width: 1170px) {
  .page--unit-type {
    .btn-see-detail {
      min-width: 0;
      width: 160px;
      padding: 10px 0;
      font-size: 18px;
      height: 46px;
    }

    .card-unit__area {
      margin-left: -5px;
      margin-right: -5px;
      li {
        font-size: 16px;
        line-height: 19px;
        padding: 4px 10px;
      }
      span {
        font-size: 13px;
        margin-top: 3px;
      }
    }

    .card-unit__ct-btn {
      height: 46px;
    }
  }
}

@media(max-width: 1023px) {
  .page--unit-type {
    .btn-see-detail {
      font-size: 18px;
      line-height: 22px;
      width: 170px;
      padding: 8px 0;
    }

    .card-unit__content {
      height: 390px;
      padding: 20px 20px 39px 20px;
    }

    .card-unit__gallery {
      height: 390px;
    }

    .card-unit__description {
      ul {
        margin-bottom: 14px;
        li {
          font-size: 15px;
          padding-bottom: 8px;
        }
      }

      p {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 22px
      }
    }

    .card-unit__thumb {
      height: 190px;
    }

    .card-unit__h {
      margin-bottom: 20px;
      font-size: 28px;
      line-height: 38px;
    }

    .primary-banner {
      height: 300px;
    }
  }
}

@media(max-width: 978px) {
  .page--unit-type {
    .btn-see-detail {
      height: 32px;
      font-size: 14px;
      line-height: 18px;
      width: 130px;
      padding: 4px 0;
    }

    .card-unit__area {
      margin-bottom: 4px;
      li {
        font-size: 14px;
        line-height: 15px;
        padding: 2px 10px;
      }
      span {
        font-size: 12px;
      }
    }

    .card-unit__ct-btn {
      height: auto;
    }

    .card-unit__description {
      ul {
        margin-bottom: 8px;
      }
    }
  }
}


@media(max-width: 890px) {
  .page--unit-type {

    .card-unit {
      margin-bottom: 40px;
    }

    .card-unit__content {
      padding: 20px 15px;
    }

    .card-unit__h {
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 30px;
    }

    .card-unit__description {
      ul {
        li {
          padding-bottom: 3px;
          line-height: 18px;
        }
      }
      p {
        margin-bottom: 15px;
      }
    }

  }
}

@media(max-width: 768px) {
  .page--unit-type {
    .banner-placeholder {
      padding: 31px 16px 0 16px;
    }

    .banner-placeholder__btn {
      width: 159px;
      height: 35px;
    }

    .banner-placeholder__p {  
      letter-spacing: 0.44px;
    }

    .btn-see-detail {
      position: static;
      width: 157px;
      height: 35px;
      border-radius: 2px;
      border: 1px solid transparent;
    }

    .card-unit__area {
      display: block;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 23px;
      li {
        padding: 0 15px;
      }
      span {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        margin-top: 3px;
      }
    }

    .card-unit-mobile {
      margin-left: -16px;
      margin-right: -16px;
      box-shadow: 0 1px 3px 0 rgba(0,0,0,.15);
      margin-bottom: 13px;
    }
    
    .card-unit-mobile__content {
      padding: 13px 16px 17px 16px;
      background-color: #f6f6f6;
      p {
        letter-spacing: -0.2px;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        span{
          font: inherit !important; 
          color: inherit !important;
        }
      }
    }

    .card-unit-mobile__img {}

    .content-unit-type {
      padding: 30px 16px 0 16px;
    }
  }
}

@media screen and (min-width: 521px)
and (max-width: 768px)
and (orientation: portrait){
    .card-unit-mobile__img{
        &.img-cover_h180{
            height: 320px;
        }
    }
}