.header{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    z-index: 300;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15);
    .header__top{
        height: 28px;
        background: #F6F6F6;
        padding: 0 42px;
    }
    .header__bottom{
        background-color: #fff;
        padding: 7px 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 74px;
    }
    .m--navbar{
        display: none;
        .contact{
            display: inline-block;
            padding: 7px 12px;
            background-color: #D4B16F;
            color: #fff;
            border-radius:2px;
        }
        .m--navbar__icon{
            height: 20px;
            width: 20px;
            position: relative;
            display: inline-block;
            cursor: pointer;    
            margin-left: 20px;
            span{
                position: absolute;
                width: 20px;
                height: 3px;
                transition-timing-function: cubic-bezier(.55,.055,.675,.19);
                transition-duration: 75ms;
                transition-property: transform;
                border-radius: 4px;
                background-color: #979797;

                top: 50%;
                display: block;
                margin-top: -2px;
                &:before{
                    display: block;
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 3px;
                    transition-timing-function: ease;
                    transition-duration: .15s;
                    transition-property: transform;
                    border-radius: 4px;
                    background-color: #979797;

                    top: -8px;
                    transition: top 75ms ease .12s,opacity 75ms ease;
                }
                &:after{
                    display: block;
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 3px;
                    transition-timing-function: ease;
                    transition-duration: .15s;
                    transition-property: transform;
                    border-radius: 4px;
                    background-color: #979797;

                    bottom: -8px;
                    transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19);
                }
            }
        }
    }
}

.header--logo{
    a{
        display: block;
    }
}

.show-dropdown-navbar{
    .header{
        .m--navbar{
            .m--navbar__icon{
                span{
                    transition-delay: .12s;
                    transition-timing-function: cubic-bezier(.215,.61,.355,1);
                    transform: rotate(45deg);
                    &:before{
                        top: 0;
                        transition: top 75ms ease,opacity 75ms ease .12s;
                        opacity: 0;
                    }
                    &:after{
                        bottom: 0;
                        transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .header{
        .lang__bar{
            justify-content: center;
        }
        .navbar{
            display: none;
        }
        .header__bottom{
            padding: 7px 16px;
            min-height: 68px;
            .header--logo{
                img{max-width: 90%;}
            }
        }
        .m--navbar{
            display: block;
        }
    }
}

// IPAD LANDSCAPE ONLY
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape){
    .header{
        .lang__bar{
            justify-content: center;
        }
        .navbar{
            display: none;
        }
        .header__bottom{
            padding: 7px 16px;
            .header--logo{
                img{max-width: 90%;}
            }
        }
        .m--navbar{
            display: block;
        }
    }
}

// IPHONE X & WINDOWS PHONE LANDSCAPE ONLY
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (orientation: landscape) { 
    .iphone,
    .windowsphone{
        .header{
            .lang__bar{
                justify-content: center;
            }
            .navbar{
                display: none;
            }
            .header__bottom{
                padding: 7px 16px;
                .header--logo{
                    img{max-width: 90%;}
                }
            }
            .m--navbar{
                display: block;
            }
        }
    }
}