.page--tenant-profile{
	.has-error{
		&:after{
			content: attr(data-text);
			margin: 0 0 0.25em;
			max-height: 5em;
			color: #de3e2a;
			font-size: 12px;
			display: block;
			width: 100%;
			padding-top: 8px;
			@extend .opensans;
		}
		input, textarea, select{
			outline: 0;
			border-bottom: 1px solid #de3e2a;
			&:focus{
				border-bottom: 1px solid #de3e2a;
			}
		}
		.field__message--error{
			color: #de3e2a;
			margin: 0.50em 0 0.25em;
			max-height: 5em;
		}
	}
	.bg-top{
		width: 100%;
		height: 261px;
		position: absolute;
		top:0;
		left:0;
		background-color: #000;
	}
	.logo{
		padding: 45px 0 20px;
		img{display: inline-block;}
	}
	.card__container{
		background-color: #fff;
		box-shadow: 0 2px 20px 0 rgba(0,0,0,0.08);
		width: 100%;
		.req-mark{
			background: #DE3E2A;
			width: 4px;
			height: 4px;
			display: inline-block;
			border-radius: 100%;
			vertical-align: middle;
			margin: 0 2px;
		}
		.card__header{
			background-image: url(/images/form-bg-lines.png);
			background-repeat: no-repeat;
			background-position: bottom;
			height: 144px;
			text-align: center;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			.title{
				font-size: 32px;
				color: #BF913E;
				letter-spacing: 0;
				@extend .playfair;
			}
			.subtitle{
				opacity: 0.7;
				font-size: 13px;
				color: #2C2B2E;
				letter-spacing: 0.47px;
				text-align: center;
				margin-top: 13px;
				@extend .raleway.semibold;
			}
		}
		.card__body{
			padding: 22px 60px 0;
			h3{
				margin-bottom: 24px;
				@extend .raleway.light;
				font-size: 24px;
				color: #BF913E;
				letter-spacing: 0.4px;

			}
			.row{
				margin-left: -16px;
				margin-right: -16px;
				.col{
					padding-left: 16px;
					padding-right: 16px;
				}
			}
			.field{
				margin-bottom: 32px;
				::-webkit-input-placeholder { color: rgba(44, 43, 46,.5); }
				::-moz-placeholder { color: rgba(44, 43, 46,.5); } /* firefox 19+ */
				:-ms-input-placeholder { color: rgba(44, 43, 46,.5); } /* ie */
				input:-moz-placeholder { color: rgba(44, 43, 46,.5); }
				.label{
					font-size: 11px;
					color: #646464;
					letter-spacing: 0.4px;
					text-transform: uppercase;
					margin-bottom: 9px;
					display: block;
					@extend .raleway.bold;
					.req-mark{margin-bottom: 3px;}
				}
				input[type="text"],
				select,
				textarea,{
					border: 0 solid transparent;
					border-bottom: 1px solid #D8D8D8;
					outline:0;
					transition: border .3s ease;
					width: 100%;
					padding: 5px 0 12px;
					@extend .opensans;
					font-size: 15px;
					color: #2C2B2E;
					letter-spacing: 0;
					&:active,
					&:focus{
						border-bottom: 1px solid #D5B67E;
					}
					&.disabled{background-color: #fff;}
				}
				select{
					background-image: url(/images/icon/ico-chevrondown.svg);
					background-repeat: no-repeat;
					background-position: 96% 40%;
					background-color: #fff;
					appearance: none;
					moz-appearance: none;
					webkit-appearance: none;
				}
				.datepick{
					background-image: url(/images/icon/ico-calendar.svg);
					background-repeat: no-repeat;
					background-position: 96% 40%;
					background-color: #fff;
					padding-right: 30px;
				}
			}
			.addmore-btn{
				text-decoration: none;
				transition: .3s ease;
				color: #BF913E;
				@extend .raleway.semibold;
				position: relative;
				padding-left: 14px;
				&:before{
					content: '+';
					position: absolute;
					left: 0;
					margin: auto;
					font-size: 25px;
					line-height: 16px;
				}
				&:hover{
					text-decoration: underline;
				}
			}
			.radiobtn{
				width: 120px;
				margin-right: 10px;
			}
			.check{
				display: none;
				& + label{
					@extend .opensans;
					font-size: 15px;
					color: #2C2B2E;
					letter-spacing: 0;
					position: relative;
					padding-left: 20px;
					cursor: pointer;
					&:before{
						content: '';
						width: 12px;
						height: 12px;
						background-image: url(/images/icon/radio-empty.svg);
						position: absolute;
						top:0;bottom:0;left:0;
						margin: auto;
					}
					&:after{
						content: '';
						width: 8px;
						height: 8px;
						background-image: url(/images/icon/radio-fill.svg);
						position: absolute;
						top:0;bottom:0;left:2px;
						margin: auto;	
						transition: .3s ease;
						transform: scale(0);
					}
				}
				&:checked,
				&.checked{
					& + label{
						&:after{
							transform: scale(1);
						}
					}
				}
			}
			.checkbox{
				display: none;
				& + label{
					position: relative;
					cursor: pointer;
					@extend .opensans;
					padding-left: 20px;
					display: inline-block;
					&:before{
						content: '';
						width: 12px;
						height: 12px;
						border: 1px solid #d8d8d8;
						border-radius: 2px;
						position: absolute;
						left: 0;
						top:4px;
						box-shadow: 0 0 0 0 #D4B16F inset;
						transition: all .2s ease-in-out;
					}
					&:after{
						content:'';
						width: 7px;
						height: 5px;
						position: absolute;
						top: 7px;
						left: 3px;
						background-image: url(/images/icon/ico-check.svg);
						background-position: center;
						background-repeat: no-repeat;
						transform: scale(0);
						opacity: 0;
						transition: all .2s ease-in-out .1s;
					}
				}
				&:checked,
				&.checked{
					& + label{
						&:before{
							box-shadow: 0 0 0 10px #D4B16F inset;
							border: 1px solid #D4B16F;
						}
						&:after{
							transform: scale(1);
							opacity: 1;
						}
					}
				}
			}
		}
		.card__thankyou{
			display: flex;
			justify-content: center;
			align-items: center;
			height: 250px;
			text-align: center;
			.title{
				font-size: 32px;
				color: #BF913E;
				letter-spacing: 0;
				@extend .playfair;
			}
			.desc{
				opacity: 0.7;
				font-size: 13px;
				color: #2C2B2E;
				letter-spacing: 0.47px;
				text-align: center;
				margin-top: 13px;
				@extend .raleway.semibold;
			}
		}
	}
}

.datepicker--cell.-selected-, .datepicker--cell.-selected-.-focus-, .datepicker--cell.-selected-.-current-{
	background: #BF913E;
}
.datepicker--cell.-current-,
.datepicker--day-name{color: #BF913E;}

@media screen and (max-width: 768px){
	.page--tenant-profile{
		.container{padding: 0;}
		.card__container{
			.card__body{
				padding: 20px;
				.field{
					margin-bottom: 25px;
					input[type="text"],
					select,
					textarea,{
						font-size: 13px;
					}
				}
				.row{
					margin-left: -8px;
					margin-right: -8px;
					.col{
						padding-left: 8px;
						padding-right: 8px;
					}
				}
			}
		}
	}
}